<template>
  <div>
    <div class="mt-6 flex md:flex-row flex-col justify-between md:items-center gap-4">
      <div class="flex items-center gap-2 page-title p-4">
        <div :style="$route.query.tab === tab ? 'background: #1C5BFE; color: white' : ''" class="py-2 px-4 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
          v-for="tab in cycleCountTabs" :key="tab.index" @click="$emit('changeTabFilter', tab)">
          <div class="text-sm">{{$t(tab)}}</div>
        </div>
      </div>
      <cycle-count-actions class="md:self-center self-end" v-if="activeTab === 4" :scannedOrders="scannedOrders" :cycleStarted="cycleStarted" @scannedOrders="scannedOrders = $event" @cycleStarted="cycleStarted = $event" @loadMissingData="loadMissingData = $event"/>
    </div>
    <div v-if="activeTab === 4">
      <not-scanned-orders :loadMissingData="loadMissingData" @loadMissingData="loadMissingData = $event"/>
    </div>
    <shipblu-card v-else class="mt-6 p-5">
      <p v-if="scannedOrders.length === 0" class="text-center">{{'No Data Available'}}</p>
      <p v-for="(item, index) in scannedOrders" :key="index">{{ item }}</p>
    </shipblu-card>
  </div>
</template>

<script>
import CycleCountActions from './components/CycleCountActions.vue'
import NotScannedOrders from './NotScannedOrders.vue'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['activeTab'],
  data () {
    return {
      cycleCountTabs: ['Not Scanned', 'Scanned'],
      scannedOrders: [],
      cycleStarted: false,
      loadMissingData: false
    }
  },
  components: {
    CycleCountActions,
    NotScannedOrders,
    ShipbluCard
  },
  created () {
    this.scannedOrders = localStorage.getItem('scannedOrders') ? JSON.parse(localStorage.getItem('scannedOrders')) : []
    this.cycleStarted = !!localStorage.getItem('scannedOrders')
  }
}
</script>