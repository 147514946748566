<template>
  <div>
    <div class="flex flex-wrap items-center gap-2">
      <template v-if="cycleStarted">
        <vs-button class="py-2 px-4 text-sm leading-normal" color="darkgrey" @click="resetCycle">{{$t('Rest Cycle Count')}}</vs-button>
        <vs-button class="py-2 px-4 text-sm leading-normal" color="primary" @click="openScanModal">{{$t('Continue Cycle Count')}}</vs-button>
      </template>
      <vs-button v-else class="py-2 px-4 text-sm leading-normal" color="primary" @click="openScanModal">{{$t('Start Cycle Count')}}</vs-button>
    </div>

    <shipblu-prompt
      class="scan-modal"
      @close="closeModal"
      :active.sync="scanModal"
      :title="$t('Scan Orders')"
      :buttons-hidden="true">
      <div class="mb-6">
        <input id="scanId" name="tracking No." v-validate="'numeric'" @input="pushTracking(tracking)" :label-placeholder="$t('Tracking No.')" v-model="tracking" class="w-full py-12 px-6 rounded font-medium text-center"/>
        <span class="text-danger text-sm" v-show="errors.has('tracking No.')">{{ errors.first('tracking No.') }}</span>
      </div>
      <div class="flex gap-2 flex-wrap">
        <p v-for="(item, index) in scannedTrackings" :key="index" class="px-2 py-1 border border-solid border-grey-light rounded-md">{{ item }}</p>
      </div>
      <div class="grid sm:grid-cols-2 gap-4 mt-4">
        <vs-button class="text-grey-dark sm:order-1 order-2" color="lightgrey" @click="closeModal">{{ $t('Cancel') }}</vs-button>
        <vs-button class="sm:order-2 order-1" @click="whatIsMissing" :disabled="scannedTrackings.length === 0">{{ $t('what’s missing') }}</vs-button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import i18nData from '@/i18n/i18nData.js'

export default {
  props: ['scannedOrders', 'cycleStarted'],
  data () {
    return {
      scanModal: false,
      tracking: '',
      scannedTrackings: []
    }
  },
  watch: {
    scanModal (val) {
      if (val === true) {
        setTimeout(() => {
          const input = document.getElementById('scanId') 
          input.focus()
        })
        this.scannedTrackings = localStorage.getItem('inProgressScannedTrackings') ? JSON.parse(localStorage.getItem('inProgressScannedTrackings')) : []
      }
    }
  },
  methods: {
    openScanModal () {
      this.scanModal = true
    },
    closeModal () {
      this.scanModal = false
    },
    pushTracking (tracking) {
      this.$validator.validateAll().then(result => {
        if (tracking !== '' && result) {
          this.scannedTrackings.push(tracking)
          localStorage.setItem('inProgressScannedTrackings', JSON.stringify(this.scannedTrackings))
          setTimeout(() => {
            this.tracking = ''
          }, 200)
        }
      })
    },
    whatIsMissing () {
      this.scannedOrders = [...this.scannedOrders, ...this.scannedTrackings]
      this.$emit('scannedOrders', this.scannedOrders)
      this.$emit('cycleStarted', true)
      localStorage.setItem('scannedOrders', JSON.stringify(this.scannedOrders))
      localStorage.setItem('inProgressScannedTrackings', [])
      this.closeModal()
      this.$emit('loadMissingData', true)
    },
    resetCycle () {
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure you want to reset cycle count?'],
        acceptText: i18nData[this.$i18n.locale]['Yes'],
        accept: this.confirmResetCycle
      })
    },
    confirmResetCycle () {
      this.$emit('scannedTrackings', [])
      this.$emit('scannedOrders', [])
      localStorage.setItem('inProgressScannedTrackings', [])
      localStorage.setItem('scannedOrders', [])
      this.$emit('cycleStarted', false)
      this.$emit('loadMissingData', true)
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>

<style>
.con-vs-dialog.scan-modal .vs-dialog {
  max-width: 550px;
}
#scanId {
  outline: none;
  border: 1px solid #737373;
  color: #737373;
  letter-spacing: 10px;
  line-height: 45.6px;
  font-size: 40px;
}
</style>