<template>
  <vx-card>
    <div v-if="taskList.merchant_agent">
      <div class="flex mb-base">
        <img class="w-1/4" src="../../../assets/images/logo/ShipBlu Logo Large.png">
      </div>
      <div v-if="taskList.id" class="vx-row mb-base">
        <div class="vx-col w-1/2 mt-4">
          <label class="font-semibold">{{$t('ID')}}</label>
          <h5>{{taskList.id}}</h5>
        </div>
        <div class="vx-col w-1/2 mt-4">
          <label class="font-semibold">{{$t('Created On')}}</label>
          <h5>{{taskList.created ? new Date(taskList.created).toLocaleDateString('fr-CA') : ''}}</h5>
        </div>
      </div>
      <div class="order-label">
        <table v-if="taskList.id" style="counter-reset: section;" class="table text-center w-1/2 mb-16" border="1">
          <caption class="font-semibold caption">RTOs Summary</caption>
          <thead bgcolor="#00ffff" class="thead">
            <tr> 
              <td class="text-center font-semibold border">#</td>
              <td class="text-center font-semibold border">Merchant Name</td>
              <td class="text-center font-semibold border">Count of RTO</td>
            </tr> 
          </thead>
          <tr v-for="(merchant, index) in merchantOrders" :key="index">
            <td>
              <span> </span>
            </td>
            <td>
              {{index}}
            </td>
            <td>
              {{merchantOrders[index].length}}
            </td> 
          </tr>
          <tfoot bgcolor="#00ffff" class="font-semibold tfoot">
            <tr>
              <td colspan="2">Total</td>
              <td>{{returns.length}}</td>
            </tr>
          </tfoot>
        </table>

        <table v-if="returns.length > 0" class="table text-center w-full" border="1">
          <thead class="thead">
            <tr> 
              <td class="text-center font-semibold border">Merchant Name</td>
              <td class="text-center font-semibold border">Tracking Number</td>
              <td class="text-center font-semibold border">Customer Name</td>
              <td class="text-center font-semibold border">Count of Packages</td>
              <td class="text-center font-semibold border">Merchant order reference</td>
              <td class="text-center font-semibold border">Merchant Signature</td>
            </tr> 
          </thead>
          <tr v-for="(order, index) in returns" :key="index">
            <td>
              {{order.merchant.name}}
            </td>
            <td>
              {{order.tracking_number}}
            </td>
            <td>
              <!-- {{order.customer.full_name}} -->
            </td>
            <td>
              {{order.number_of_packages}}
            </td>
            <td>
              {{order.merchant_order_reference}}
            </td>
            <td width="25%" :class="index === 0 || order.merchant.id === returns[index - 1].merchant.id ? 'border-t-0 border-b-0' : 'border-t-1 border-b-0'">
            </td>
          </tr>
        </table>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: ['taskList', 'returns', 'merchantOrders']
}
</script>

<style lang="scss">
@media print{
  .caption {
    border-style: solid solid hidden solid;
    border-width: 1px;
    background: #00ffff;
  }
  body {
    counter-reset: section;
  }
  span::before {
    counter-increment: section;
    content: counter(section);
  }
  @page {
    size: letter;
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  #fc_frame {
    display: none;
  }
  .shipment-page,
  .dropdown-menu {
    *{
      visibility: hidden;
      color: black;
    }
    #counter-label-print,
    #counter-label-print *{
      visibility: visible;
    }
    #counter-label-print{
      display: block;
    }
    #content-area {
      margin: 0 !important;
    }
    #counter-label-print {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
    .order-label{
      padding: 0px;
    }
    .table {
      border-collapse: collapse;
      border: 1px solid black;
    }
  }
}
</style>
